import React, { useState } from "react";
import { Transition } from 'react-transition-group';
import styles from "./styles.module.scss";

const FAQ = ({ faq }) => {
	const [ faqOpen, setFaqOpen ] = useState(false);
	const [ faqHeight, setFaqHeight ] = useState(0);

	const toggleFaq = () => {
		if( faqOpen ){
			setFaqHeight(0);
		}
		setFaqOpen(!faqOpen);
	}

	const faqEnter = (node, isAppearing) =>{
		setFaqHeight(node.offsetHeight);
	}

	const faqTransitionStyles = {
		maxHeight: faqHeight + "px",
		transition: 'max-height 0.2s ease-in-out',
		position: 'relative'
 	};

	 const faqContainerTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 1,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};
	
	return (
		<li className={styles.faq}>
			<button className={faqOpen ? styles.toggleactive : styles.toggle} onClick={()=>{ toggleFaq(); }}>{faq.title}</button>
			<div className={styles.answercontainer} style={faqTransitionStyles}>
				<Transition key={faq.id} in={faqOpen} timeout={250} mountOnEnter unmountOnExit onEnter={(node,isAppearing)=>{ faqEnter(node, isAppearing) }}>
					{(state) => (
						<div className={styles.answer} dangerouslySetInnerHTML={{__html: faq.ACFFaq.faqAnswer}} style={{...faqContainerTransitionStyles[state]}}></div>
					)}
				</Transition>
			</div>
		</li>
	);
};

const FAQAccordion = ({ faqs }) => {
	return (
		<div className={styles.FAQAccordion}>
			<h3 className={styles.listtitle}>FAQs</h3>
			<ul className={styles.list}>
				{ faqs.map((faq, index) => {
					return <FAQ key={faq.id} faq={faq} />
				})}
			</ul>
		</div>
	);
}

export default FAQAccordion;