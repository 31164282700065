import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import LocationSelector from "../components/locationSelector";
import ServiceSelector from "../components/serviceSelector";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import FAQAccordion from "../components/faqAccordion";
import styles from "./service.module.scss";
// SVG 
import arrowIconOrange from '../images/svg-icons/arrow-orange.svg'

export default (props) => {

	const {
		data: {
			service,
			service: {
				id: serviceId,
				title,
				ACFService: {
					serviceInfo: {
						left: {
							requestAppointmentLink,
							faqs,
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							serviceContent: content
						}
					}
				}
			},
			locations: {
				nodes: serviceLocations
			},
			services: {
				nodes: services
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			plaqusterNatalie,
			plaqusterChuck,
			arrowBlue,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage,
		}
	} = props;

	const locations = serviceLocations.filter((location, index) => {

		if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.right.allServices ){
			const serviceIds = location.ACFLocation.locationInfo.right.allServices.map(service =>
				`${service.id}`,
			);

			return serviceIds && serviceIds.includes(serviceId);
		} else {
			return null;
		}
	});

	return (
		<Layout hideCtaButton={true}>
			<Seo post={service} />
			<div className={`${styles.top} hero`}>
				
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }

					<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />

					<div className={styles.natalie}>
						<FluidImage image={plaqusterNatalie} loading={"eager"} fadeIn={false} alternativeText={'Natalie the plaquster, a Leap dental brand character'} />
					</div>
					<div className={styles.chuck}>
						<FluidImage image={plaqusterChuck} loading={"eager"} fadeIn={false} alternativeText={'Chuck the plaquster, a Leap dental brand character'} />
					</div>

				<div className={styles.left}>					
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}

					<div className={styles.desktopbreadcrumbs}>
						<span className={styles.loclink}>Services</span>
						<span className={styles.locationname}>{title}</span>
					</div>

					<h2 className={styles.servicename} dangerouslySetInnerHTML={{__html: title}}></h2>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} passedClass={styles.link} icon={arrowIconOrange} iconIsSVG={true} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
					
				</div>
				<div className={styles.right}>
					<div className={styles.lefttop}>
						{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
						<h1 className={styles.servicename} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styles.leftbottom}>
						<span className={styles.serviceheading}>This service is offered at the following locations:</span>
						{ locations && locations.length > 0 && <LocationSelector locations={locations} /> }
						<div className={styles.locationlist}>
							<div className={styles.inner}>
								<ul className={styles.list}>
									{ locations && locations.map((location, index) => {
										return (
											<li key={`lstloc-'${location.id}`} className={styles.listitem}>
												<Link to={location.link} className={styles.link}>
													<FixedImage passedClass={styles.icon} image={arrowBlue} />
													<span className={styles.title} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
												</Link>	
											</li>
										)
									})}
								</ul>
							</div>
						</div>
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styles.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styles.request}>Request appointment</Link> }
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
			<div className={styles.left}>
					<div className={styles.servicecontent}>
						<div className={styles.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
				</div>
				<div className={styles.right}>
					<ServiceSelector services={services} />
					{ faqs && <FAQAccordion faqs={faqs} /> }
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		service: wpService(id: { eq: $id }) {
			id
			title
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			ACFService {
				serviceInfo {
					left {
						requestAppointmentLink {
							title
							url
							target
						}
						faqs {
							... on WpFaq {
								id
								ACFFaq {
									faqAnswer
								}
								title
							}
						}
					}
					right {
						introContent {
							blurb
							heading
							link {
								target
								title
								url
							}
						}
						serviceContent
					}
				}
			}
		}
		services: allWpService(sort: {fields: title}, filter: {id: {ne: $id}}) {
			nodes {
			  title
			  link
			  id
			}
		}
		locations: allWpLocation(sort: {fields: title}){
			nodes {
			  title
			  id
			  link
			  ACFLocation {
				menuTitle
				locationInfo {
					right {
						allServices {
						  ... on WpService {
							id
						  }
						}
					  }
				}
			  }
				birdeyeLocation {
					location {
						city
						state
					}
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		teamPage: allWpPage(filter: {template: {templateName: {eq: "Team Page"}}}) {
			nodes {
			  link
			}
		}
		appointmentPage: allWpPage(filter: {template: {templateName: {eq: "Appointments Page"}}}) {
			nodes {
			  link
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		plaqusterNatalie: file(name: {eq: "natalie"}) {
			childImageSharp {
				fluid(maxWidth: 140){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		plaqusterChuck: file(name: {eq: "chuck"}) {
			childImageSharp {
				fluid(maxWidth: 175){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
	}
`;
